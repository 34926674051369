import React from 'react';

interface Job {
  title: string;
  company: string;
  period: string;
  description: string;
}

const jobs: Job[] = [
  {
    title: "Senior Software Engineer / Tech Lead",
    company: "Vinta Technologies Inc",
    period: "February 5, 2024-present",
    description: "My focus is on meeting deadlines while exploring new technologies that keep us at the cutting edge of our industry. I genuinely enjoy mentoring junior developers and watching them grow in their roles. I also take care of server configurations and handle critical tasks that require careful attention to detail. A big part of my job involves engaging with clients in meetings, where I discuss technical matters and guide them through our processes, making sure they feel informed and supported every step of the way."
  },
  {
    title: "Sr. Backend Developer",
    company: "Mossaic Solutions",
    period: "September 11, 2023-February 1, 2024",
    description: "In my role, I support the project lead in transitioning from monolithic projects to streamlined REST API operations. I'm also responsible for reviewing past implementations to identify and resolve issues that have been causing ongoing problems, all while ensuring that our processes continue to run smoothly."
  },
  {
    title: "Sr. Backend Developer",
    company: "Cartrack Technologies Phil. Inc",
    period: "June 2021 – July 31, 2023",
    description: "I work closely with the technical lead to tackle complex business logic challenges by offering architectural solutions. My role also involves developing new features, fixing bugs, and managing database automation tasks. Additionally, I create UML diagrams for pub/sub systems like Socket.IO and PG_NOTIFY."
  },
  {
    title: "Backend Developer",
    company: "Teko Solutions Asia Inc",
    period: "February 2019 – June 2021",
    description: "As a software engineer, I handle critical bugs, develop new features, and optimize performance, ensuring smooth updates to production. I also contribute to frontend tasks and manage database automation to keep everything running efficiently."
  },
  { 
    title: "Web Developer",
    company: "MegaSportsWorld",
    period: "August 2017 - January 2019",
    description: "As a systems engineer, I collaborate with stakeholders to understand their project needs and then design and develop systems to meet those requirements. I’m also responsible for setting up servers and troubleshooting any bugs that come up during development. I believe in teamwork and regularly share my knowledge with the team so we can all grow and succeed together."
  },
  {
    title: "Jr. Web Developer",
    company: "Fulgar Development Corp",
    period: "November 2016 - August 2017",
    description: "I’m a systems engineer specializing in creating, developing, and deploying new systems. I prioritize data security by ensuring sensitive information is encrypted and protected. I also troubleshoot and fix bugs in existing systems to keep everything running smoothly."
  },
  {
    title: "Technical Support Associate",
    company: "Titanium System Technologies Inc",
    period: "August 2015 - November 2016",
    description: "I’m responsible for making sure data is properly managed and handled across all devices. I also work directly with new clients, providing demonstrations and troubleshooting support to help them quickly get up and running with our products or services."
  },
];

const Experience: React.FC = () => {
  return (
    <section id="experience" className="mb-5">
      <h2 className="mb-4">Experience</h2>
      <div className="accordion" id="experienceAccordion">
        {jobs.map((job, index) => (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button 
                className={`accordion-button ${index !== 0 ? 'collapsed' : ''}`} 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target={`#collapse${index}`} 
                aria-expanded={index === 0 ? 'true' : 'false'} 
                aria-controls={`collapse${index}`}
              >
                {job.title} - {job.company}
              </button>
            </h2>
            <div 
              id={`collapse${index}`} 
              className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} 
              aria-labelledby={`heading${index}`} 
              data-bs-parent="#experienceAccordion"
            >
              <div className="accordion-body">
                <p><strong>{job.period}</strong></p>
                <p>{job.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Experience;