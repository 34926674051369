import React from 'react';

const About: React.FC = () => {
  return (
    <section id="about" className="mb-5">
      <h2 className="mb-4">About Me</h2>
      <p>As a Software Engineer with 9 years of experience, I specialize in leveraging agile frameworks to solve complex technical challenges. For nearly 4 years, I've taken on the roles of Tech Lead and Lead Software Engineer, where I guide my team in meeting client expectations and ensuring timely project delivery. I'm also responsible for designing database schemas, developing network architectures, and tackling intricate business logic to drive successful outcomes.</p>
    </section>
  );
};

export default About;