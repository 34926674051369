import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="bg-primary text-white text-center py-5 mt-5">
      <div className="container">
        <h1 className="display-4">Mark Anthony Naluz</h1>
        <p className="lead">Software Engineer | Tech Lead | Problem Solver</p>
      </div>
    </header>
  );
};

export default Header;