import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-custom-dark text-custom-light text-center py-3">
      <div className="container">
        <p>Contact: anthony.naluz15@gmail.com | Phone: (+63) 9190950915</p>
        <p>Address: Kapalaran St. Brgy. Barangka Drive Mandaluyong City</p>
      </div>
    </footer>
  );
};

export default Footer;