import React from 'react';

interface SkillCategory {
  name: string;
  skills: string[];
}

const skillCategories: SkillCategory[] = [
  {
    name: "Backend",
    skills: ["Laravel", "Codeigniter", "Symfony", "NodeJS", "Python"]
  },
  {
    name: "Frontend",
    skills: ["ReactJS", "Typescript"]
  },
  {
    name: "Database & DevOps",
    skills: ["MySQL", "PostgreSQL", "Docker / Docker Swarm", "Linux", "AWS", "DigitalOcean"]
  }
];

const Skills: React.FC = () => {
  return (
    <section id="skills" className="mb-5">
      <h2 className="mb-4">Skills</h2>
      <div className="row">
        {skillCategories.map((category, index) => (
          <div className="col-md-4 mb-3" key={index}>
            <h5>{category.name}</h5>
            <ul className="list-unstyled">
              {category.skills.map((skill, skillIndex) => (
                <li key={skillIndex}>
                  <i className="bi bi-check-circle-fill text-success me-2"></i>{skill}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;