import React from 'react';
import Header from './components/includes/Header';
import Navigation from './components/includes/Navigation';
import About from './components/pages/About';
import Experience from './components/includes/Experience';
import Education from './components/includes/Education';
import Skills from './components/includes/Skills';
import Footer from './components/includes/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

const App: React.FC = () => {
  return (
    <>
      <Navigation />
      <Header />
      <main className="container my-5">
        <About />
        <Experience />
        <Education />
        <Skills />
      </main>
      <Footer />
    </>
  );
};

export default App;